import React from 'react'
import ContactCard from '../card/contact-card'

function Contact({ ref }) {
    return (
        <div
            ref={ref}
            id='contact'
            className={`flex w-full bg-darkBlue px-[27px] py-[40px]`}
        >
            <div
                className={`grid w-full grid-cols-1 grid-rows-2 gap-[30px] lg:grid-cols-3 lg:grid-rows-1`}
            >
                <div
                    className={`flex w-full flex-col justify-center items-center lg:justify-start lg:items-start`}
                >
                    <h2 className={`font-semibold text-[30px] lg:text-[56px] text-textWhite text-center`}>Contact Us</h2>
                </div>
                <div
                    className={`flex flex-col w-full gap-y-[30px] justify-center`}
                >
                    <ContactCard 
                        icon={'/email.svg'}
                        contact={'rayaadversinvestama@gmail.com'}
                    />
                </div>
                <div
                    className={`flex flex-col w-full justify-center`}
                >
                    <ContactCard 
                        icon={'/location.svg'}
                        contact={'Jl. Bakti No.10, RT.1/RW.6, Rw. Bar., Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12180'}
                    />
                </div>
            </div>
        </div>
    )
}

export default Contact