import React from 'react'

function ServicesCard({
    title,
    description,
    icon,
}) {
    return (
        <div
            className={`flex gap-x-6 items-center h-fit`}
        >
            <div className={`flex w-[40px] justify-center items-center`}>{icon}</div>
            <div className={`flex flex-col justify-center gap-y-2`}>
                <span className={`font-semibold text-[20px] text-blue text-left`}>{title}</span>
                <span className={`font-normal text-[18px] text-textGrey text-left`}>{description}</span>
            </div>
        </div>
    )
}

export default ServicesCard