import { Drawer } from 'antd'
import React from 'react'

function MobileNavDrawer({
    open,
    onClose,
    scrollToAbout,
    scrollToService,
    scrollToReview,
    scrollToContact,
}) {
    return (
        <Drawer
            open={open}
            onClose={onClose}
            closable={true}
            className='w-screen md:w-[350px]'
            title={false}
            styles={{
                header: {
                    border: 0,
                }
            }}
        >
            <div
                className='flex w-full flex-col gap-y-4 p-4'
            >
                <button
                    onClick={scrollToAbout}
                    className={`flex w-full justify-center items-center py-3 shadow-md rounded-lg`}
                >
                    <span className={`text-base font-semibold text-orange`}>About</span>
                </button>
                <button
                    onClick={scrollToService}
                    className={`flex w-full justify-center items-center py-3 shadow-md rounded-lg`}
                >
                    <span className={`text-base font-semibold text-orange`}>Services</span>
                </button>
                <button
                    onClick={scrollToReview}
                    className={`flex w-full justify-center items-center py-3 shadow-md rounded-lg`}
                >
                    <span className={`text-base font-semibold text-orange`}>Reviews</span>
                </button>
                <button
                    onClick={scrollToContact}
                    className={`flex w-full justify-center items-center py-3 shadow-md rounded-lg`}
                >
                    <span className={`text-base font-semibold text-orange`}>Contacts</span>
                </button>
            </div>
        </Drawer>
    )
}

export default MobileNavDrawer