import { Image } from 'antd'
import React from 'react'
import ServicesCard from '../card/services-card'
import { FaProjectDiagram } from "react-icons/fa"
import { BsFileBarGraph, BsGraphUpArrow } from "react-icons/bs"
import { LiaProjectDiagramSolid } from "react-icons/lia"
import { MdOutlineSupportAgent } from 'react-icons/md'
import { HiOutlineArrowPath } from "react-icons/hi2"

function Services({ ref }) {
    return (
        <div
            ref={ref}
            id='service'
            className={`hidden lg:flex justify-center items-center w-full lg:px-[60px] xl:px-[96px] gap-x-12 xl:gap-x-[128px] mb-[96px]`}
        >
            <div
                className={`flex flex-col gap-y-[39px] max-w-[382px]`}
            >
                <h2 className={`text-[56px] font-semibold text-darkBlue text-left`}>Our Services</h2>
                <div
                    className={`flex flex-col gap-y-6`}
                >
                    <ServicesCard 
                        icon={ <LiaProjectDiagramSolid size={40} color='#D37B1D'/> }
                        title={'Project Matching'}
                        description={'We find and connect investors with sustainable projects that match their values and objectives.'}
                    />
                    <ServicesCard 
                        icon={ <BsFileBarGraph size={40} color='#D37B1D'/> }
                        title={'Green Investment Portfolios'}
                        description={'We help investors build diverse portfolios that generate returns while making a positive environmental and social impact.'}
                    />
                    <ServicesCard 
                        icon={ <MdOutlineSupportAgent size={60} color='#D37B1D'/> }
                        title={'Startup Support'}
                        description={'We offer guidance and resources to green startups seeking investment, helping them grow and succeed.'}
                    />
                    <ServicesCard 
                        icon={ <HiOutlineArrowPath size={60} color='#D37B1D'/> }
                        title={'Sustainability Education'}
                        description={'We provide easy-to-understand workshops and resources to keep clients informed and empowered in the world of green business.'}
                    />
                </div>
            </div>
            <div
                className={`flex flex-col gap-y-4 xl:hidden`}
            >
                <div
                    className={`flex gap-x-4`}
                >
                    <Image 
                        src={'/project-matching.webp'}
                        preview={false}
                        width={250}
                        height={200}
                        rootClassName='shadow-lg'
                    />
                    <Image 
                        src={'/investment.webp'}
                        preview={false}
                        width={250}
                        height={200}
                        rootClassName='shadow-lg'
                    />
                </div>
                <div
                    className={`flex gap-x-4`}
                >
                    <Image 
                        src={'/startup.webp'}
                        preview={false}
                        width={250}
                        height={200}
                        rootClassName='shadow-lg'
                    />
                    <Image 
                        src={'/sustainability.webp'}
                        preview={false}
                        width={250}
                        height={200}
                        rootClassName='shadow-lg'
                    />
                </div>
            </div>
            <div
                className={`hidden flex-col gap-y-4 xl:flex`}
            >
                <div
                    className={`flex gap-x-4`}
                >
                    <Image 
                        src={'/project-matching.webp'}
                        preview={false}
                        width={350}
                        height={300}
                        rootClassName='shadow-lg'
                    />
                    <Image 
                        src={'/investment.webp'}
                        preview={false}
                        width={350}
                        height={300}
                        rootClassName='shadow-lg'
                    />
                </div>
                <div
                    className={`flex gap-x-4`}
                >
                    <Image 
                        src={'/startup.webp'}
                        preview={false}
                        width={350}
                        height={300}
                        rootClassName='shadow-lg'
                    />
                    <Image 
                        src={'/sustainability.webp'}
                        preview={false}
                        width={350}
                        height={300}
                        rootClassName='shadow-lg'
                    />
                </div>
            </div>
        </div>
    )
}

export default Services