import React from 'react'
import { BiSolidQuoteAltLeft } from 'react-icons/bi'
import { FaRegUserCircle } from "react-icons/fa"

function ReviewCard({
    review,
    customer,
    position,
}) {
    return (
        <div
            className={`flex w-[320px] h-[385px] flex-col bg-lightGrey rounded-[16px] py-[14px] gap-y-0 lg:h-[300px] xl:h-[280px] lg:w-[450px] xl:w-[600px]`}
        >
            <div
                className={`flex w-full mb-[6px] mx-6 border-box`}
            >
                <BiSolidQuoteAltLeft color='#D37B1D' size={30}/>
            </div>
            <div
                className={`flex w-full border-box mb-[18px] px-[50px]`}
            >
                <p className={`text-[14px] font-normal text-darkGrey`}>{`"${review}"`}</p>
            </div>
            <div
                className={`flex w-full gap-x-4 items-center px-6`}
            >
                <FaRegUserCircle color='#5C6B70' size={40}/>
                <div
                    className={`flex flex-col gap-y-0 justify-center`}
                >
                    <span className={`font-semibold text-base text-darkBlue text-left`}>{customer}</span>
                    <span className={`font-normal text-base text-grey text-left`}>{position}</span>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard