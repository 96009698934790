import logo from './logo.svg';
import './App.css';
import RootLayout from './components/layout/layout';
import HeroSection from './components/contents/hero';
import About from './components/contents/about';
import MobileServices from './components/contents/services-m';
import Services from './components/contents/services';
import Reviews from './components/contents/reviews';
import Contact from './components/contents/contact';
import { useRef } from 'react';

function App() {

    const heroRef = useRef(null);
    const aboutRef = useRef(null);
    const serviceRef = useRef(null);
    const serviceMRef = useRef(null);
    const reviewRef = useRef(null);
    const contactRef = useRef(null);

    return (
        <RootLayout
            heroRef={heroRef}
            aboutRef={aboutRef}
            serviceRef={serviceRef}
            serviceMRef={serviceMRef}
            reviewRef={reviewRef}
            contactRef={contactRef}
        >
            <HeroSection 
                ref={heroRef}
            />
            <About 
                ref={aboutRef}
            />
            <MobileServices 
                ref={serviceMRef}
            />
            <Services 
                ref={serviceRef}
            />
            <Reviews 
                ref={reviewRef}
            />
            <Contact 
                ref={contactRef}
            />
        </RootLayout>
    );
}

export default App;
