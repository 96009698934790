import { Image } from 'antd'
import React from 'react'

function ServiceMCard({ 
    title, 
    description,
    image,
}) {
    return (
        <div
            className={`flex flex-col items-center gap-y-4`}
        >
            <Image 
                src={image}
                preview={false}
                width={300}
                height={250}
            />
            <h4 className={`font-semibold text-blue text-base text-center`}>{title}</h4>
            <span className={`font-normal text-textGrey text-[12px] text-center`}>{description}</span>
        </div>
    )
}

export default ServiceMCard