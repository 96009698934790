import { Image } from 'antd'
import React from 'react'

function ContactCard({
    icon,
    contact,
}) {
    return (
        <div
            className={`flex gap-x-5 items-center`}
        >
            <div
                className={`flex w-[30px]`}
            >
                <Image 
                    src={icon}
                    height={30}
                    width={30}
                    preview={false}
                />
            </div>
            <h5 className={`font-normal text-base text-white text-left`}>{contact}</h5>
        </div>
    )
}

export default ContactCard