import React from 'react'
import CardAbout from '../card/about-card'

import { TbReportAnalytics, TbWorldSearch } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { MdCastForEducation, MdDataExploration } from "react-icons/md";

function About({ ref }) {
    return (
        <div
            ref={ref}
            id='about'
            className={`flex flex-col w-full justify-center items-center px-[38px] py-[73px] lg:pt-[73px] lg:pb-[120px]`}
        >
            <div
                className={`flex flex-col justify-center items-center gap-y-5 mb-9 max-w-[1024px]`}
            >
                <h1 className={`text-darkBlue font-semibold text-[30px] lg:text-[56px] tracking-widest text-center italic`}>Your Concrete Integrated Solutions to Ensure a Nature-Positive Future</h1>
                <h2 className={`font-medium text-[20px] lg:text-[30px] text-textBlack text-center`}>Investment. Solutions. Innovation. Partnership. Knowledge</h2>
                <h3 className={`font-normal text-[12px] lg:text-[18px] text-textGrey text-center`}>At Rain Venture, we believe that sustainability must be supported by combination of strategic investment, market-driven innovation, and supportive partnerships. We are not only connecting investors and making green projects, but we also enabling the transition to sustainability through knowledge management.</h3>
            </div>
            <div
                className={`grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 gap-[25px] lg:gap-[60px] lg:max-w-[660px]`}
            >
                <CardAbout 
                    title={"Sustainable Investment Portfolios"}
                    description={"We help you build investment portfolios that are both green and profitable."}
                    icon={ <TbReportAnalytics size={60} color='#498DA6'/> }
                />
                <CardAbout 
                    title={"Project Discovery"}
                    description={" We find promising green projects for your investments."}
                    icon={ <TbWorldSearch size={60} color='#498DA6'/> }
                />
                <CardAbout 
                    title={"Business Accelerations"}
                    description={"We guide green startups to success."}
                    icon={ <BiSupport size={60} color='#498DA6'/> }
                />
                <CardAbout 
                    title={"Sustainability Education"}
                    description={"We offer easy-to-understand resources to keep you informed about green business."}
                    icon={ <MdDataExploration size={60} color='#498DA6'/> }
                />
            </div>
        </div>
    )
}

export default About