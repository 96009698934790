import { Card } from 'antd'
import React from 'react'

function CardAbout({
    icon,
    title,
    description,
}) {
    return (
        <Card
            title={false}
            rootClassName={`fit-content shadow-lg hover:shadow-xl ease-in-out`}
            className={`fit-content shadow-lg hover:shadow-xl ease-in-out`}
            bordered={false}
        >
            <div className={`flex w-[250px] h-[313px] lg:w-[300px] lg:h-[361px] flex-col items-center pt-10 lg:pt-9 px-7 gap-y-[30px]`}>
                {icon}
                <h4 className={`font-semibold text-base lg:text-[20px] text-textBlack text-center`}>{title}</h4>
                <h5 className={`font-normal text-[14px] lg:text-[18px] text-textGrey text-center`}>{description}</h5>
            </div>
        </Card>
    )
}

export default CardAbout