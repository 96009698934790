import React from 'react'
import ServiceMCard from '../card/services-m-card'

function MobileServices({ ref }) {
    return (
        <div
            ref={ref}
            id='service-m'
            className={`flex lg:hidden flex-col w-full justify-center items-center px-[27px] mb-[74px] gap-y-[32px]`}
        >
            <div
                className={`flex w-full justify-center items-center`}
            >
                <h2 className={`text-[30px] font-semibold text-darkBlue text-center`}>Our Services</h2>
            </div>
            <div
                className={`flex flex-col w-full items-center gap-y-[60px]`}
            >
                <ServiceMCard 
                    image={'/project-matching.webp'}
                    title={'Project Matching'}
                    description={'We find and connect investors with sustainable projects that match their values and objectives.'}
                />
                <ServiceMCard 
                    image={'/investment.webp'}
                    title={'Green Investment Portfolios'}
                    description={'We help investors build diverse portfolios that generate returns while making a positive environmental and social impact.'}
                />
                <ServiceMCard 
                    image={'/startup.webp'}
                    title={'Startup Support'}
                    description={'We find and connect investors with sustainable projects that match their values and objectives.'}
                />
                <ServiceMCard 
                    image={'/sustainability.webp'}
                    title={'Sustainability Education'}
                    description={'We provide easy-to-understand workshops and resources to keep clients informed and empowered in the world of green business.'}
                />
            </div>
        </div>
    )
}

export default MobileServices