import React from 'react'
import ReviewCard from '../card/review-card'
import { Carousel } from 'antd'


function Reviews({ ref }) {
    return (
        <div
            ref={ref}
            id='review'
            className={`flex flex-col w-full gap-y-8 lg:gap-y-[53px] mb-[128px]`}
        >
            <div
                className={`flex w-full justify-center items-center px-[46px] lg:px-[60px] xl:px-[96px]`}
            >
                <h2 className={`font-semibold text-darkBlue text-[30px] lg:text-[56px]`} >Customer Reviews</h2>
            </div>
            <Carousel
                centerMode={false}
                slidesToShow={1}
                dots={false}
                rootClassName='block md:hidden'
                draggable={true}
                autoplay={true}
                autoplaySpeed={2000}
                pauseOnHover={true}
                pauseOnFocus={true}
            >
                <ReviewCard 
                    customer={"Laksamana M.S."}
                    position={"CEO of Penyerapan Karbon Khatulistiwa"}
                    review={"Thanks to Rain Venture's exceptional support, Penyerapan Karbon Khatulistiwa secured vital funding through their network of investors. This partnership has not only boosted our sustainability efforts but also our company's growth. Rain Venture made it happen!"}                        
                />
                <ReviewCard 
                    customer={"Anthony H.M.S."}
                    position={"CEO of Agri Bumi Sejahtera"}
                    review={"Rain Venture played a pivotal role in expanding our supply and demand market, connecting us to the right partners and investors. Their support has been instrumental in the growth and prosperity of Agri Bumi Sejahtera. We couldn't have done it without Rain Venture!"}                        
                />
                <ReviewCard 
                    customer={"Beniqno J.P.S."}
                    position={"CEO of Gigih Harmoni Sejahtera"}
                    review={"Rain Venture has been our guiding light on the path to success for green startups. Their education and support have been invaluable in helping Gigih Harmoni Sejahtera thrive in the sustainability sector. Thanks to Rain Venture, we're reaching new heights."}                        
                />
                <ReviewCard 
                    customer={"Harini S."}
                    position={"CEO at Konsultan Karbon Indonesia"}
                    review={"Rain Venture opened doors to a world of opportunities, connecting Konsultan Karbon Indonesia to clients and end buyers. Their network and support have been invaluable in expanding our reach and success. We're grateful for the partnership with Rain Venture."}                        
                />
                <ReviewCard 
                    customer={"Adam H."}
                    position={"CEO of Konsultan Lingkungan Hidup Karbon"}
                    review={"Rain Venture's expertise and service were pivotal in helping Konsultan Lingkungan Hidup Karbon navigate the complex process of forest certification for ARR and REDD+ along with carbon credit certification. Their support has been invaluable in achieving our environmental goals, and we're thankful for their guidance and partnership."}                        
                />
            </Carousel>
            <Carousel
                centerMode={true}
                slidesToShow={2}
                dots={false}
                rootClassName='hidden md:block'
                draggable={true}
                autoplay={true}
                autoplaySpeed={2000}
                pauseOnHover={true}
                pauseOnFocus={true}
            >
                <ReviewCard 
                    customer={"Laksamana M.S."}
                    position={"CEO of Penyerapan Karbon Khatulistiwa"}
                    review={"Thanks to Rain Venture's exceptional support, Penyerapan Karbon Khatulistiwa secured vital funding through their network of investors. This partnership has not only boosted our sustainability efforts but also our company's growth. Rain Venture made it happen!"}                        
                />
                <ReviewCard 
                    customer={"Anthony H.M.S."}
                    position={"CEO of Agri Bumi Sejahtera"}
                    review={"Rain Venture played a pivotal role in expanding our supply and demand market, connecting us to the right partners and investors. Their support has been instrumental in the growth and prosperity of Agri Bumi Sejahtera. We couldn't have done it without Rain Venture!"}                        
                />
                <ReviewCard 
                    customer={"Beniqno J.P.S."}
                    position={"CEO of Gigih Harmoni Sejahtera"}
                    review={"Rain Venture has been our guiding light on the path to success for green startups. Their education and support have been invaluable in helping Gigih Harmoni Sejahtera thrive in the sustainability sector. Thanks to Rain Venture, we're reaching new heights."}                        
                />
                <ReviewCard 
                    customer={"Harini S."}
                    position={"CEO at Konsultan Karbon Indonesia"}
                    review={"Rain Venture opened doors to a world of opportunities, connecting Konsultan Karbon Indonesia to clients and end buyers. Their network and support have been invaluable in expanding our reach and success. We're grateful for the partnership with Rain Venture."}                        
                />
                <ReviewCard 
                    customer={"Adam H."}
                    position={"CEO of Konsultan Lingkungan Hidup Karbon"}
                    review={"Rain Venture's expertise and service were pivotal in helping Konsultan Lingkungan Hidup Karbon navigate the complex process of forest certification for ARR and REDD+ along with carbon credit certification. Their support has been invaluable in achieving our environmental goals, and we're thankful for their guidance and partnership."}                        
                />
            </Carousel>
        </div>
    )
}

export default Reviews