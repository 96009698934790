import React from 'react'

function Navigation({
    text,
    onClick,
}) {
    return (
        <button
            onClick={onClick}
            className={`flex text-blue hover:text-orange border-b-2 border-white hover:border-orange py-2`}
        >
            <span className={`lg:text-[18px] font-normal`}>{text}</span>
        </button>
    )
}

export default Navigation