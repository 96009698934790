import React from 'react'

function HeroSection({
    children,
    ref
}) {


    const handleClick = () => {
        window.open(`https://api.whatsapp.com/send?phone=628111861945`, '_blank');
    }

    return (
        <div
            ref={ref}
            id='hero'
            className='flex w-full bg-[url("../public/mobile-bg.png")] lg:bg-[url("../public/desktop.webp")] bg-cover bg-no-repeat bg-center'
        >
            <div
                className='flex flex-col justify-center items-center w-full h-full bg-[rgba(31,74,90,0.22)] lg:bg-[rgba(13,31,38,0.42)] pt-[141px] pb-[141px] px-4 lg:px-[60px] xl:px-[96px]'
            >
                <div
                    className={`flex justify-center items-center mb-7 lg:mb-6`}
                >
                   <h2 className={`text-white font-semibold text-[30px] lg:text-[60px] text-center`}>Empowering Sustainable Innovation, Unlocking Strategic Investment at Scale</h2> 
                </div>
                <div
                    className={`flex justify-center items-center mb-7 lg:mb-[50px]`}
                >
                   <h2 className={`text-white font-normal text-[14px] lg:text-[20px] text-center`}>Sustainability is at a core of our business models. Enhancing prosperity and spreading kindness are elements of our mission that drive us building our projects with integrity and persistency. We’re happy to welcome you in joining our exciting journey to ensure a nature positive future through a creation of sustainable investment projects.</h2> 
                </div>
                <button
                    onClick={() => handleClick()}
                    className={`flex justify-center items-center py-[13px] px-[18px] bg-white rounded-lg hover:bg-darkBlue text-textBlack hover:text-white`}
                >
                    <span className={`text-[16px] lg:text-[20px] font-semibold`}>Contact Us</span>
                </button>
            </div>
        </div>
    )
}

export default HeroSection