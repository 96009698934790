import { Image, Layout } from 'antd'
import React, { useState } from 'react'
import Navigation from '../buttons/nav-button';
import MobileNavDrawer from '../drawer/drawer-nav';

const { Header, Content } = Layout;

function RootLayout({
    children,
    heroRef,
    aboutRef,
    serviceRef,
    serviceMRef,
    reviewRef,
    contactRef,
}) {


    const scrollToHero = () => {
        const element = document.getElementById('hero');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToAbout = () => {
        const element = document.getElementById('about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToService = () => {
        const element = document.getElementById('service');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToMService = () => {
        const element = document.getElementById('service-m');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToReview = () => {
        const element = document.getElementById('review');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToContact = () => {
        const element = document.getElementById('contact');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const [open, setOpen] = useState(false)

    return (
        <Layout
            className={`w-screen min-h-screen border-box relative bg-white overflow-x-hidden`}
        >
            <Header
                className={`flex w-full justify-between items-center px-4 lg:px-[60px] xl:px-[96px] py-3 bg-white border-box h-fit leading-none`}
            >
                <button
                    className="flex justify-center items-center lg:hidden"
                >
                    <Image 
                        src='/logo.png'
                        preview={false}
                        width={40}
                    />
                </button>
                <button
                    onClick={() => setOpen(true)}
                    className="flex justify-center items-center lg:hidden"
                >
                    <Image 
                        src='/menu_icon.svg'
                        preview={false}
                        width={30}
                    />
                </button>
                <button
                    className="hidden justify-center items-center lg:flex"
                >
                    <Image 
                        src='/logo.png'
                        preview={false}
                        width={65}
                    />
                </button>
                <div
                    className='hidden lg:flex justify-center items-center gap-x-8 h-fit'
                >
                    <Navigation 
                        onClick={(e) => scrollToHero()}
                        text={"Home"}
                    />
                    <Navigation 
                        onClick={(e) => scrollToAbout()}
                        text={"About"}
                    />
                    <Navigation 
                        onClick={(e) => scrollToService()}
                        text={"Services"}
                    />
                    <Navigation 
                        onClick={(e) => scrollToReview()}
                        text={"Reviews"}
                    />
                    <Navigation 
                        onClick={(e) => scrollToContact()}
                        text={"Contact Us"}
                    />
                </div>
            </Header>
            <Content
                className={`flex w-full flex-col`}
            >
                {children}
            </Content>
            <MobileNavDrawer 
                open={open}
                onClose={() => setOpen(false)}
                scrollToAbout={() => {
                    setOpen(false)
                    scrollToAbout()
                }}
                scrollToContact={() => {
                    setOpen(false)
                    scrollToContact()
                }}
                scrollToReview={() => {
                    setOpen(false)
                    scrollToReview()
                }}
                scrollToService={() => {
                    setOpen(false)
                    scrollToMService()
                }}
            />
        </Layout>
    )
}

export default RootLayout